import React, { useState } from "react";
import { Link } from "gatsby";
import logo from "../../static/logo.png";

function Header () {

  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="flex items-center justify-between flex-wrap bg-white p-6">
      <div className="flex items-center flex-shrink-0 text-lblue mr-6">
        <a href="/"><img className="w-3/5" alt="Cédric LE HIR - Psychothérapie et Coaching Troyes" src={logo} /></a>
        <span className="font-semibold text-xl tracking-tight"></span>
      </div>
      <div className="block lg:hidden">
        <button onClick={() => toggleExpansion(!isExpanded)} className="flex items-center px-3 py-2 border rounded text-green-500 border-teal-400 hover:text-lblue hover:border-white">
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>
      <div className={`${ isExpanded ? `block` : `hidden` } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}>
        <div className="text-m lg:flex-grow">
        <Link to={`/confinement`} href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-red-700 hover:text-red-500 mr-4">
            COVID
          </Link>
          <Link to={`/services`} href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-green-500 hover:text-lblue mr-4">
            Services proposés
          </Link>
          <Link to={`/groupe-enfant-adolescent/estime-de-soi`} className="block mt-4 lg:inline-block lg:mt-0 text-green-500 hover:text-lblue mr-4">
            Groupes enfants-ados
          </Link>
          <Link to={`/groupes/soutien-parentalite`} className="block mt-4 lg:inline-block lg:mt-0 text-green-500 hover:text-lblue mr-4">
            Soutien à la parentalité
          </Link>
          <Link to={`/psychopraticien/cedric-le-hir`} className="block mt-4 lg:inline-block lg:mt-0 text-green-500 hover:text-lblue mr-4">
            Praticien
          </Link>
          <Link to={`/methode`} className="block mt-4 lg:inline-block lg:mt-0 text-green-500 hover:text-lblue mr-4">
            Méthode
          </Link>
          <Link to={`/blog`} className="block mt-4 lg:inline-block lg:mt-0 text-green-500 hover:text-lblue mr-4">
            Blog
          </Link>
          <Link to={`/contact`} className="block mt-4 lg:inline-block lg:mt-0 text-green-500 hover:text-lblue mr-4">
            Contact
          </Link>
        </div>
        <div>
          <a href="https://perfactive.fr/cedric-le-hir/location?" target="_blank" rel="noopener" className="inline-block text-sm px-4 py-2 leading-none border rounded text-green-500 border-white hover:border-transparent hover:text-blue-100 hover:bg-green-600 mt-4 lg:mt-0">Prendre RDV</a>
        </div>
      </div>
    </nav>
  )
}

export default Header;
