import React from "react";
import { Link } from "gatsby";
import cx from "classnames";

const Footer = ({ className, ...props }) => {
  className = cx(
    "flex flex-wrap px-8 py-4 text-white bg-gray-800 justify-between items-start md:items-center",
    className
  );
  return (
    <>
      <div className={className} {...props}>
        <div className="flex text-xl font-semibold m-3"><Link to="/">Psychothérapie et coaching à Troyes</Link></div>

        <div  className="flex-initial m-3">
          <div className="text-xl font-semibold mx-3"><Link to="/psychotherapie/enfant">Enfants</Link></div>
          <ul className="text-sm font-medium">
            <li className="mx-3">TDAH</li>
            <li className="mx-3">Hypersensibilité</li>
            <li className="mx-3">Troubles du sommeil</li>
            <li className="mx-3">Harcèlement scolaire</li>
            <li className="mx-3">Précocité</li>
          </ul>
        </div>

        <div className="flex-initial m-3">
          <div className="text-xl font-semibold mx-3"><Link to="/psychologue/adolescent">Adolescents</Link></div>
          <ul className="text-sm font-medium">
            <li className="mx-3">Difficultés scolaires</li>
            <li className="mx-3">Scarifications</li>
            <li className="mx-3">Anorexie/Boulimie</li>
            <li className="mx-3">Addictions</li>
            <li className="mx-3">Choix des études</li>
          </ul>
        </div>

        <div className="flex-initial m-3">
          <div className="text-xl font-semibold mx-3"><Link to="/developpement-personnel/adulte">Adultes</Link></div>
          <ul className="text-sm font-medium">
            <li className="mx-3">Stress</li>
            <li className="mx-3">Depression</li>
            <li className="mx-3">Psychotraumatisme</li>
            <li className="mx-3">Deuil</li>
            <li className="mx-3">Troubles obsessionnels compulsifs</li>
          </ul>
        </div>

        <div  className="flex-initial m-3">
          <div className="text-xl font-semibold mx-3">Autres accompagnements</div>
          <ul className="text-sm font-medium">
            <li className="mx-3">Supervision éducateurs-enseignants</li>
            <li className="mx-3"><Link to="/groupe-enfant-adolescent/estime-de-soi">Groupes habiletés sociales</Link></li>
            <li className="mx-3"><Link to="/groupes/soutien-parentalite">Soutien à la parentalité</Link></li>
            <li className="mx-3">Développement personnel</li>
          </ul>
        </div>


        <div className="flex-initial text-xl font-semibold m-3">
          <ul>
            <li className="m-2 text-xl font-semibold"><Link to="/contact">Contact</Link></li>
            <li className="m-2 text-xl font-semibold"><a href="https://clhpsy.appointlet.com" target="_blank" rel="noopener">RDV en ligne</a></li>
          </ul>
        </div>
      </div>

      <div className="w-full px-8 py-2 text-white text-xs font-light bg-black flex flex-col sm:flex-row justify-between items-start md:items-center">
        <div>
          <strong className="font-bold">Téléphone: </strong>
          03-10-94-04-13
          <strong className="ml-3 font-bold">Email: </strong>
          cedric@le-hir.name
          <strong className="ml-3 font-bold">Adresse: </strong>
          90 route d'Auxerre - 10120 St André les vergers
        </div>
        <div>
          <Link to="/" className="hover:underline">
            www.psytroyes.com
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
